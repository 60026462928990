@import url("https://use.typekit.net/jyj1kxf.css");

#websiteRoot {
  font-family: greycliff-cf, sans-serif;
}

.ant-modal-body {
  background-color: black;
  color: white !important;
  border-radius: 20px;
}

.ant-modal-title {
  color: white !important;
}
.ant-btn-primary {
  background-color: #ee9c65;
  border-color: #ee9c65;
}

.ant-modal-content {
  background-color: transparent;
}